<template>
  <table
    cellspacing="0"
    cellpadding="0"
    border="0"
    role="presentation"
  >
    <tbody>
      <tr>
        <!-- Avatar column -->
        <td style="vertical-align: middle; padding-right: 15px; padding-top: 20px;">
          <img
            v-if="options.company.selected === 'Lengow'"
            :src="`${options.baseUrl}/img/icons/logo_full.png`"
            style="height: 45px;
          vertical-align: middle;"
            alt="logo"
          >
          <img
            v-if="options.company.selected === 'Netrivals'"
            :src="`${options.baseUrl}/img/logo_ln.png`"
            style="width: 80px;
          vertical-align: middle;"
            alt="logo"
          >
        </td>
        <!-- Info column -->
        <td style="vertical-align: middle; padding-top: 20px;">
          <table
            cellspacing="1"
            cellpadding="0"
            border="0"
            role="presentation"
            style="font-size: 0;"
          >
            <tbody>
              <!-- Name and job fields -->
              <tr>
                <td>
                  <span
                    v-if="options.company.selected === 'Lengow'"
                    style="font-size: 16px; line-height: 1; font-family: Arial, Helvetica, sans-serif; color: #001049; font-weight: 700;"
                  >{{
                    mainFields[0].value | capitalize }}</span>
                  <span
                    v-if="options.company.selected === 'Netrivals'"
                    style="font-size: 16px; line-height: 1; font-family: Arial, Helvetica, sans-serif; color: #e53738; font-weight: 700;"
                  >{{
                    mainFields[0].value | capitalize }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span style="font-size: 14px; line-height: 1; color: #555;">{{ mainFields[1].value | capitalize
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'BlocTop',

  computed: {
    ...mapState(['options'])
  },

  /* filters: {
    capitalize: function (value) {
      if (!value) return ''
      return value.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())
    }
  }, */

  props: {
    mainFields: {
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
</script>
